.selectable {
    cursor: pointer;
    &.selected,
    &:hover {
        background-color: #ccc
    }
}

.clickable-ico {
    :hover {
        cursor: pointer;
    }
}