.summary-card {
  width: 20rem;
  height: 100%;
}

.red {
  color: indigo;
}

.card-bg-colour {
   background-color: rgba(135, 187, 250, 0.1);
}

.card-div-bg-colour {
   background-color: rgba(135, 187, 250, 0.1);
}

.top5-stats.card-body .tab-content {
  height: 100% !important;
}