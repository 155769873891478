.confirm-email {
  .input-feedback_error {
    color: #bd2222;
    font-weight: bold;
    padding-left: 10px;

    ul {
      list-style-position: inside;
    }
  }
}
