.custom-search-table {
  tbody {
    tr {
      &:hover {
        background-color: #ddd;
        cursor: pointer;
      }
    }
  }

  .date-range {
    width: 35%;
  }

  .stamp {
    color: #fff;
    padding: 0.2rem;
    font-size: 0.7em;
    border-radius: 50%;
    font-weight: bold;
    padding: 0.5em;
  }

  .main-meter {
    background-color: #0d6efd;
  }

  .sub-meter {
    background-color: #5699fe;
  }
}
