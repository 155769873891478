$placeholder-color: #AAA;
$placeholder-style: italic;

.modal-header {
    background-color: #1689CA;
    color: #FFF;
}

.device-information-modal {
    span.red{
        color: red;
    }
}

.device-information-form {
    label {
        font-weight: 700;
    }
    
    ::-webkit-input-placeholder {
        color: $placeholder-color;
        font-style: $placeholder-style;
    }
    
    :-moz-placeholder { /* Firefox 18- */
        color: $placeholder-color; 
        font-style: $placeholder-style;
        opacity:  1;
    }
    
    ::-moz-placeholder {  /* Firefox 19+ */
        color: $placeholder-color;  
        font-style: $placeholder-style;
        opacity:  1;
    }
    
    :-ms-input-placeholder {  
        color: $placeholder-color;  
        font-style: $placeholder-style;
    }
    
}
