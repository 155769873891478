/* ==========================================================================
    General
========================================================================== */

.clickable {
  cursor: pointer;
}

th i {
  padding-left: 20px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* ==========================================================================
    Background
========================================================================== */

.background {
  background-image: url('../res/bg.jpg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-clip: border-box;
  background-size: cover;

  display: flex;
  height: 100vh;
  width: 100%;
}

/* ==========================================================================
    Search
========================================================================== */

.search-section {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.search-bar {
  position: relative;
  width: 40%;
}

.search-bar .search-icon {
  position: absolute;
  z-index: 0;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.search-bar .clear-icon {
  position: absolute;
  cursor: pointer;
  z-index: 0;
  top: 10px;
  right: 10px;
  text-align: center;
  color: sienna;
}

.form-control {
  padding-left: 2.375rem;
  padding-right: 2.375rem;
}

.result-number {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 700;
}

/* ==========================================================================
    Chart
========================================================================== */

.chart-container {
  position: relative;
  margin: auto;
}
.canvas-container {
  // height: 60vh;
}

/* ==========================================================================
    Modal
========================================================================== */

.custom-modal-footer {
  display: flex;
  justify-content: flex-start;

  #close {
    margin-left: auto;
  }
}

//hide edge default password reveal button
::-ms-reveal {
  display: none;
}

/* ==========================================================================
        Battery
    ========================================================================== */

.battery-container {
  display: flex;
  align-items: center;
}

td .fas::before {
  padding-right: 10px;
}

.fa-battery-full::before {
  color: #15961d;
}

.fa-battery-three-quarters::before {
  color: #66cd00;
}

.fa-battery-half::before {
  color: #d0d200;
}

.fa-battery-quarter::before {
  color: #ff802a;
}

.fa-battery-empty::before {
  color: #e74343;
}

.battery-level {
  font-weight: 800;
}

/* ==========================================================================
    Signal Level
    ========================================================================== */

// * { box-sizing: border-box; }
.signal-container {
}

$signal-strength-bar-height: 24px;
$signal-strength-bar-width: 60px;

.sizing-box {
  height: $signal-strength-bar-height;
  width: $signal-strength-bar-width;
}

.signal-bars {
  display: inline-block;
  left: 24%;
}

.signal-bars .bar {
  width: 10%;
  margin-left: 4%;
  min-height: 20%;
  display: inline-block;
}
.signal-bars .bar.first-bar {
  height: 25%;
}
.signal-bars .bar.second-bar {
  height: 50%;
}
.signal-bars .bar.third-bar {
  height: 75%;
}
.signal-bars .bar.fourth-bar {
  height: 100%;
}
// .signal-bars .bar.fifth-bar  { height: 100%; }

.good .bar {
  background-color: #16a085;
  border: thin solid darken(#16a085, 7%);
}
.bad .bar {
  background-color: #e74c3c;
  border: thin solid darken(#e74c3c, 20%);
}
.ok .bar {
  background-color: #f1c40f;
  border: thin solid darken(#f1c40f, 7%);
}

.four-bars .bar.fifth-bar,
.three-bars .bar.fifth-bar,
.three-bars .bar.fourth-bar,
.one-bar .bar:not(.first-bar),
.two-bars .bar:not(.first-bar):not(.second-bar) {
  background-color: #a9a9a9;
  border: thin solid #a9a9a9;
}
