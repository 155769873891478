@import '../../../../common/utils/style-constants.scss';

.water-usage {
  padding: 10px;

  .main-content {
    display: flex;
    flex-direction: column;
  }
  .data-container {
    padding-top: 2em;

    .data-visualizations {
      display: flex;
      flex-direction: column;
    }
  }

  .datePicker {
    width: 100%;
  }

  .row {
    padding: 8px;
  }

  .row b {
    padding-right: 10px;
  }

  .range .col-md-1 {
  }

  .range {
    .date-picker {
      display: flex;
      flex-direction: column;
    }
    
    .col-md-3 {
      @media (max-width: 768px) {
        // flex: 0 0 25%;
        // max-width: 25%;
      }
    }
    
  }

  .btn-primary {
    color: #454545;
    background-color: #FFF;
  }

  .section-header {
    align-self: center;
    font-weight: 800;
  }

  .btn-group {
    z-index: 0;
  }

  .react-datepicker__input-container input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    text-align: center;
    background-color: hsl(0, 0%, 100%);
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: $portrait-screen-upper-boundary) {
      flex: 0 0 41.66667%;
      // max-width: 41.66667%;
    }

    /* -webkit-align-items: center; */
      /* -webkit-box-align: center; */
      /* -ms-flex-align: center; */
      /* align-items: center; */
      /* cursor: default; */
      /* display: -webkit-box; */
      /* display: -webkit-flex; */
      /* display: -ms-flexbox; */
      /* display: flex; */
      /* -webkit-box-flex-wrap: wrap; */
      /* -webkit-flex-wrap: wrap; */
      /* -ms-flex-wrap: wrap; */
      /* flex-wrap: wrap; */
      /* -webkit-box-pack: justify; */
      /* -webkit-justify-content: space-between; */
      /* justify-content: space-between; */
      /* outline: 0!important; */
      /* position: relative; */
      /* -webkit-transition: all 100ms; */
      /* transition: all 100ms; */
  }
  

  .canvas-container {
    padding: 20px 0;
    
    canvas {
      // height: 100% !important;
      width: 100% !important;
      // height: 100%;
      background-color: #FFF;
    }
  }
  
  .table-container {
    padding-top: 40px;
    
    .table {
      table-layout: fixed;
      thead {
        background: #f0f0f0;
      }

      tr:nth-child(even) {
        background-color: #eee;
      }

      tr:hover {
        background: #ddd;
      }

      thead tr, tbody tr {
        :nth-child(-n+2) {
          // background-color: #DDD;
          // color: white;
        }
      }
  }

  }
}
