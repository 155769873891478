#report {
  padding: 0;

  #steps-html {
    background-color: white;
    border: 1px solid black;
    width: 180mm;
    font-size: 9pt;
    //height: 297mm;
    padding: 1.5em;
    ol {
      margin-left: 2rem;
    }
  }
  .report-page {
    position: relative;
    //position: absolute;
    //top: -1000px;
    //left: -1000px;
    .header-img {
      width: calc(100% + 2em);
      margin: -1em;
      margin-bottom: 1em;
    }
    .footer-img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    width: 210mm;
    height: 297mm;
    padding: 1em;
    background-color: white;
    border: 1px solid black;
    &.landscape {
      width: 297mm !important;
      height: 210mm !important;
      .chartjs-render-monitor {
        max-height: 120mm !important;
        max-width: 277mm !important;
      }
    }
    h2 {
      margin: 1.5rem;
      margin-top: 1em;
      color: #1689ca;
      font-family: 'Poppins', sans-serif;
      font-size: 32px;
      font-weight: 800;
      line-height: 1.2em;
    }
    .address {
      margin-top: 1em;
      margin-left: 1.5em;
      margin-bottom: 1.5em;
      font-size: 0.9em;
      width: 300px;
      .first-line {
        color: #1689ca;
        font-weight: 800;
        font-size: 1em;
      }
      .smartflow-line {
        display: block;
        color: #1689ca;
        font-size: 0.7em;
        border-bottom: 1px solid #1689ca;
        margin-bottom: 1em;
      }
    }
  }
}

@media (max-width: 650px) {
  .multiselect-container {
    width: 100% !important;
  }
  .device-information-form {
    width: 100% !important;
  }
}
