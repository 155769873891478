.alert-queue-item {
  .chart-container {
    position: relative;
    margin: auto;
  }
  .canvas-container {
    height: 60vh;
  }


  /* ==========================================================================
  Accordion
  ========================================================================== */

  .accordion .card .card-header .btn {
    color: rgb(70, 70, 70);
    font-size: larger;
  }

  @media only screen and (max-width: 990px){
    .alert-queue-container .card-body{
      padding: 0rem;

    }
  }
}