body,
html {
  padding: 0;
  margin: 0;
}

.page__loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: #007dc5;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  svg {
    height: 282px;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -55%);
    #rect4487 {
      fill: #007dc5;
    }
  }

  .loader {
    height: 200px;
    width: 174px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
  }

  .water {
    background: white;
    position: relative;
    top: 50%;
    height: 200%;
    width: 200%;
    z-index: 1000;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 1s ease;
    animation:
      wave 5s linear infinite,
      movetop 10s linear forwards;
  }

  @keyframes movetop {
    0% {
      top: 60%;
    }
    100% {
      top: 10px;
    }
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
