.comments-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment-container {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  // padding: 16px;
  padding: 0.5em;
  margin: 15px 0 15px 0;
  width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.author {
  font-size: 0.75em;
  color: #868686;
}

.text {
  font-size: 1em;
  margin-top: 1.5em;
}

.comment:last-child {
  margin-bottom: 0;
}

.header {
  display: flex;

  justify-content: space-between;
}

.timestamp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}
