.device-status {
  /* ==========================================================================
    General
    ========================================================================== */

  /* ==========================================================================
    Status Filter Tabs
    ========================================================================== */

  .status-filter-container {
    padding-bottom: 20px;
    width: 50%;
  }

  .nav-pills span {
    margin-right: 5px;
    transition-duration: 0.4s;
    cursor: pointer;
  }

  .inactive {
    border: 1px solid #1689ca;
    &:hover {
      background-color: #a1c0d2;
      color: #fff;
    }
  }

  /* ==========================================================================
    Signal Level
    ========================================================================== */

  .device-disconnected {
    background-color: #dcdcdc;
  }
}
