.navigation-buttons-container {
    display: flex;
    justify-content: space-between;
}

.chart-container {
    margin-bottom: 40px;
}

.total-usage {
    text-align: center;
}
