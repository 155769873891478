.rtf-management {
    .basic-multi-select {
        .select__menu {
            z-index: 2;
        }
    }
    
    .section-header {
        align-self: center;
    }


    .no-device-message {
        padding-top: 20px;
        font-style: italic;
        color: darkgray;
    }

    .rtf-view-container {
        width: 100%;
        height: 290px;
        max-width: 290px;

        canvas {
            width: 100%;
        }
    }
}
