.current-water-usage {
  
  .multiselect_text {
    padding-left: 5px;
    // margin-bottom: 5px;
  
  }
  .canvas-container {
    height: 60vh;
  }
   
}