.topbar {
  z-index: 999;
  background-color: rgba(135, 187, 250, 0.1) !important;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  display: flex;
  justify-content: space-between;

  .multiselect-container {
    font-size: 0.9em;
    max-width: 20em;
  }

  .account-selector {
    display: flex;
    width: 80%;
    // justify-content: space-between;
    align-items: center;
    // justify-content: center;

    .account-selector_label {
      font-weight: 700;
    }

    Form {
      margin-top: 3%;
      padding-left: 2%;
      width: 60%;
    }

    .multiselect-container {
      margin-left: 10px;
      flex-basis: 80%;
    }
  }

  .account-icons-container {
    display: flex;
    // flex-direction: column;
  }

  .current-user {
    display: flex;
  }

  .user-dropdown {
    // margin:auto;
    // justify-content: flex-end;

    button:after {
      display: none;
    }
    > button {
      width: 40px;
      height: 40px;
      padding: 6px 0px;
      border-radius: 50%;
      text-align: center;
      // font-size: 12px;
      line-height: 1.42857;
    }
  }

  .sidebar-brand-icon {
    > img {
      width: 250px;
      height: 55px;
    }
  }

  @media (max-width: 650px) {
    height: 100px;

    .account-icons-container {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }

    .sidebar-brand-icon {
      > img {
        width: 110px;
        height: 32px;
      }
    }
  }
}
