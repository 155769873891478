@import '../../../../common/utils//style-constants.scss';

.invoice {
  padding: 10px;

  .main-content {
    display: flex;
    flex-direction: column;
  }
  .invoice-container {
    width: 700px;

    .data-visualizations {
      display: flex;
      flex-direction: column;
      height: 300px !important;
      width: 400px !important;
    }
  }

  .datePicker {
    width: 100%;
  }

  .row {
    padding: 8px;
  }

  .row b {
    padding-right: 10px;
  }

  .range {
    .date-picker {
      display: flex;
      flex-direction: column;
    }
  }

  .btn-primary {
    color: #454545;
    background-color: #fff;
  }

  .section-header {
    align-self: center;
    font-weight: 800;
  }

  .tenants-list {
    border-bottom: 1px dashed #000;
  }

  .tenants-list:last-child {
    border-bottom: none;
  }

  .react-datepicker__input-container input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    text-align: center;
    background-color: hsl(0, 0%, 100%);
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: $portrait-screen-upper-boundary) {
      flex: 0 0 41.66667%;
    }
  }
}
