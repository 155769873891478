.login-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 500px;
  padding: 2em 5em;

  @media (max-width: 500px) {
    padding: 2em;
    height: 100%;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
  }

  form {
    button {
      margin-top: 1em;
      width: 100%;
    }
  }

  .forgot-password-link {
    padding-top: 20px;
    font-size: 18px;

    a {
      color: #000 !important;
    }
  }
}
