.device-list-container {
  max-height: 16em;
  overflow-y: auto;
  overflow-x: auto;
}

.device-status-list {
  margin-left: 2em;

  .leak-ico {
    color: #1689CA;
  }

  .unplugged-ico {
    color: tomato;
  }

  .power-ico {
    color: darkorange;
  }

  .device-span {
    display: inline-block;
    min-height: 2em;
  }
}

