.valves-management {
    .basic-multi-select {
        .select__menu {
            z-index: 2;
        }
    }
    
    .section-header {
        align-self: center;
    }
    
    .valve-container {
        display: flex;
        padding-top: 10px;
        // font-size: 15px;
    }
    
    .valve-number {
        padding: 0 10px 6px 0;
        font-weight: 800;
    }

    .no-device-message {
        padding-top: 20px;
        font-style: italic;
        color: darkgray;
    }
}
