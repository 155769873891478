@import '../../../../../common//utils//style-constants.scss';

$placeholder-color: #aaa;
$placeholder-style: italic;

.modal-header {
  background-color: #1689ca;
  color: #fff;
}

.device-information-modal {
  span.red {
    color: red;
  }
}

.device-information-form {
  label {
    font-weight: 700;
  }

  ::-webkit-input-placeholder {
    color: $placeholder-color;
    font-style: $placeholder-style;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder-color;
    font-style: $placeholder-style;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder-color;
    font-style: $placeholder-style;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $placeholder-color;
    font-style: $placeholder-style;
  }

  .side-note {
    padding-top: 10px;
    font-style: italic;
  }
}

.react-datepicker__input-container input {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  text-align: center;
  background-color: hsl(0, 0%, 100%);
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: $portrait-screen-upper-boundary) {
    flex: 0 0 41.66667%;
  }
}
