@import '../../../../common/utils/style-constants.scss';

.firmware-udpate-manager {
    .tables-container {
        display: flex;
        
        @media (max-width: $portrait-screen-upper-boundary) {
            flex-direction: column;
          }
    }

    .device-list {
        padding-right: 40px;
        // flex: grow shrink basis
        flex: 1;

    }

    .actions {
        border-left: 1px solid #D1D3E2;
        padding-left: 20px
    }

    .search-bar {
        width: 80%;
    }
}
